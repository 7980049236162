<template>
    <div class="animated fadeIn">
    <div id="snackbar"></div>       
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Language </strong> <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submit">
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="title">Title <span class="text-danger">*</span></label>
                <b-form-input type="text" required v-model="language.LanguageName" id="title" placeholder="Language"></b-form-input>
              </b-form-group>
            </b-col>
             <b-col sm="6">
              <b-form-group>
                <label for="country">Country <span class="text-danger">*</span></label>
               <b-form-select required v-model="language.CountryID" id="lang"
                  value-field="CountryID"
                  text-field="CountryName"
                  :plain="true"
                  :options= countries>
                  <template slot="first">
                    <option :value="0" disabled>-- Select Country --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            
          </b-row>
         
         <b-row>
           <b-col sm="4">
             <b-form-group>
                <label for="language-file">Upload File Here <span class="text-danger">*</span></label>
                <!-- <b-form-input type="text" v-model="cinema.Thumbnail" id="thumbnail" placeholder="Thumbnail Image"></b-form-input> -->
                 <b-form-file :required="language.LanguageID == null ? true : false" accept=".csv" v-on:change="uploadFile($event.target)" ref="fileinput" id="fileInput" :plain="true"></b-form-file>
                 <input type="hidden" v-model="language.LanguageFile">
              </b-form-group>
           </b-col>
           <b-col sm="4" v-if="language.LanguageID != null">
             <br>
             <a :href="LanguageUrl">Download File</a>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <label for="status">Status</label>
                <br>
                <c-switch class="mx-1" color="primary" v-model="language.LanguageIsActive" defaultChecked variant="3d" label v-bind="labelIcon" />  
              </b-form-group>
            </b-col>
         </b-row>

          <b-row>
              <b-col sm="6">
                  <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> {{ commontext.submitButton }}</b-button>
              </b-col>

            <b-col sm="6">
                  <b-button @click="resetForm" size="sm" variant="success"><i class="fa fa-dot-circle-o"></i> Reset</b-button>
              </b-col>
          </b-row>
          </b-form>
        </b-card>

         <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of Languages
        <div class="card-header-actions">
          
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
          <div slot="LanguageIsActive" slot-scope="props">
            <c-switch class="mx-1" color="primary" v-model="props.row.LanguageIsActive" variant="3d" label v-bind="labelIcon" @change.native="changeStatus(props.row.LanguageID)"/>
          </div>
         <div slot="LanguageID" slot-scope="props">
            <i class="fa fa-edit" @click="editLanguage(props.row.LanguageID)"></i>
          </div>
        </v-client-table>
      </b-card-body>
    </b-card>


      </b-col>
    </b-row>
    <b-modal id="ModalConfirmationOnSubmit" ref="ModalConfirmationOnSubmit" @ok="handleModalOkay" :title="modalObject.title" class="center-footer" footer-class="justify-content-center border-0" cancel-variant="outline-danger" ok-variant="outline-success" centered hide-header no-close-on-esc no-close-on-backdrop >
         <div class="d-block text-center">
           <div>{{ modalObject.message }}</div>
         </div>
       </b-modal>
  </div>    
</template>
<script>
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import CinemaService from "@/services/CinemaService";
import { Event } from "vue-tables-2";
import UploadService from "@/services/UploadService";

export default {
  name: "Language",
  components: {
    cSwitch,
    Event
  },
  data() {
    return {
      commontext: {
        submitButton: "Submit"
      },
      language: {
        LanguageID: null,
        Flag: 1,
        LanguageName: "",
        LanguageIsActive: "",
        LanguageFile: "",
        CountryID: 0
      },
      // BaseURL: BaseURL,
      LanguageUrl: "",
      countries: [],
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      columns: ["LanguageName", "LanguageIsActive", "LanguageID"],
      data: [],
      options: {
        headings: {
          name: "Language Name",
          LanguageIsActive: "LanguageIsActive",
          LanguageID: "LanguageID"
        },
        sortable: ["LanguageName"],
        filterable: ["LanguageName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  mounted() {
    this.getLanguages();
    this.getCountries();
  },
  methods: {
    getCountries: function() {
      // let res = await MasterService.getCountriesDDL();
      MasterService.getCountriesDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CountryList.length > 0) {
            this.countries = data.CountryList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm: function() {
      this.language = {
        LanguageID: null,
        Flag: 1,
        LanguageName: "",
        LanguageIsActive: true,
        LanguageFile: "",
        CountryID: 0
      };
      this.commontext.submitButton = "Submit";
      this.$refs.fileinput.reset();
      this.getLanguages();
    },
    getLanguages: function() {
      // let res = await MasterService.getLanguages();
      this.data = [];
      MasterService.getLanguages()
        .then(response => {
          const { data } = response;
          if (data.Status && data.LanguageList.length > 0) {
            this.data = data.LanguageList.reverse();
          } else {
            data.Message ? this.showMessageSnackbar(data.Message) : "";
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    validation: function() {
      if (this.language.LanguageName == "") {
        this.showMessageSnackbar("City Name Cannot be empty.");
        return false;
      } else if (this.language.CountryID == "") {
        this.showMessageSnackbar("Please select the Country.");
        return false;
      } else {
        return true;
      }
    },
    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");
      MasterService.addLanguage(this.language)
        .then(response => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
          if (data.Status) {
            this.resetForm();
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editLanguage: function(LanguageID) {
      let payload = {
        LanguageID
      };
      MasterService.getLanguageByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.LanguageList.length > 0) {
            this.commontext.submitButton = "Update Language";
            this.language = data.LanguageList[0];
            this.language.Flag = 2;
            this.LanguageUrl = data.LanguageList[0].LanguageUrl;
            this.scrollToTop();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    uploadFile: function(fileCSV) {
      let refs = this.$refs;
      let self = this;
      var formdata = new FormData();
      formdata.append("assets/languages", fileCSV.files[0]);
      formdata.append("type", "csv");
      formdata.append("rename", this.language.LanguageName);
      // let res = await CinemaService.uploadFiles(formdata);
      UploadService.uploadFiles(formdata)
        .then(response => {
          const { data } = response;
          data.Message ? self.showMessageSnackbar(data.Message) : "";
          if (data.Status) {
            self.language.LanguageFile = data.Path;
          }
        })
        .catch(error => {
          self.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    changeStatus: function(ID) {
      // let res = await MasterService.activateOrDeactivate(ID, "Language");
      let payload = {
        TableName: "Language",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
